import { Component } from '@angular/core';
import { GenericService } from '../../providers/generic.service';
import { GlobalService } from '../../providers/global.service';
import { DataService } from '../../providers/data.service';
import { MasterService } from '../../providers/master.service';
import { map, catchError, debounceTime, finalize } from 'rxjs/operators';
import { FusionChartsService } from 'app/providers/fusion-charts.service';
import { ChartService } from 'app/providers/chart.service';

declare var donut;

@Component({

    selector: 'screener',
    templateUrl: './screener.component.html',
    styleUrls: ['./screener.component.scss']
})

export class ScreenerComponent
{
    selectedTab = "Overall";
    title: string;
    gs = GlobalService;
    waves = [];
    showbrandchart = false;
    init = true;
    configs1: any =
        [
            { id: 'chart1', title: 'Brands you are fond of', type: "mscolumn2d", service: "freq?surveyCode=psdemo3&questCode=Q1" },
            { id: 'chart2', title: 'Consider in next 6 months', type: "mscolumn2d", service: "freq?surveyCode=psdemo3&questCode=Q2" },
            { id: 'chart31', title: 'Various factors while purchasing a new car', type: "mscolumn2d", service: "dartboard?surveyCode=psdemo3&questCode=QQ3&values=3,4" },
            { id: 'chart3', title: 'Favourite brand', type: "doughnut2d", height: "100px", showLabels: "0", service: "freq?surveyCode=psdemo3&questCode=Q3" },

        ];

    configs2: any =
        [
            { id: 'chart4', title: 'Brand Association', type: "mscolumn2d", service: "dartboard?surveyCode=psdemo3&questCode=Q4&values=0,1,2,3,4,5,6,7,8" },
            { id: 'chart41', title: 'Suggest you family or friends', type: "doughnut2d", service: "freq?surveyCode=psdemo3&questCode=QQ4" },
            { id: 'chart5', title: 'Brand Satisfaction', type: "doughnut2d", service: "t2b?surveyCode=psdemo3&questCode=Q5&values=1,2,3,4,5,6,7,8" },
            //{ id: 'chart5', title: 'Brand Satisfaction', type: "pie2d", service: "t2b?surveyCode=psdemo3&questCode=Q5&values=1,2,3,4,5,6,7,8" },
            //{ id: 'chart6', title: 'Please review the 360 degree view of brand and select the view you like the most from the options below:', type: "pie2d", service: "freq?surveyCode=psdemo3&questCode=Q6" },

        ];

    configs3: any = [];

    constructor(private masterService: MasterService, private chartService: ChartService)
    {
        this.title = "Screener";
        GlobalService.changeTitle(this.title);
        GlobalService.routeChange();
        this.run();
    }

    async ngOnInit()
    {

        this.gs.subscription = GlobalService.filtersObservable.subscribe(filters =>
        {
            this.run();
        });

        //if (GlobalService.filters && GlobalService.filters[0].List)
        //    this.run();
    }



    run()
    {
        //this.init = false;
        let filters = GlobalService.serializeFilters();
       let brandcode = this.gs.filters.filter(x => x.Title == "Brands")[0].Selected;

        for (let i = 0; i < this.configs1.length; i++)
        {
            let config = this.configs1[i];
            config.table = null;
            let service = config.service;
            let url = service;
            //if (i > 2)
             url = GenericService.addToQueryString(url, "filters", filters);
            config.loading = true;
            let sub = this.masterService.callGetService(url).pipe(
                finalize(() =>
                {
                    config.loading = false;
                    //this.loading = false;
                }))
                .subscribe(res =>
                {
                    config.table = res;

                    config.tableForExport = GenericService.clone(config.table);
                    if (this.configs1[i].id == "chart3")
                    {
                        config["height"] = '400px';
                        config["showLabels"] = '0';
                    }
                    FusionChartsService.renderChartFromTable(config);
                },
                    (err) =>
                    {
                        console.log(err);
                    });

            this.gs.ajaxSubscription.push(sub);
        }
        if (brandcode)
        {
            this.showbrandchart = true;
            let brand = this.gs.filters.filter(x => x.Title == "Brands")[0].List.filter(x => x.Id == brandcode)[0].Title;

            //let brand = this.gs.filters.filter(x => x.Title == "Brands")[0].List.filter(x => x.Id == brandcode).Title;
            this.configs3 = GenericService.clone(this.configs2);
            var re = /brand/;
            for (let i = 0; i < this.configs3.length; i++)
            {
                let config = this.configs3[i];
                config.table = null;
                config.title = config.title.replace(re, brand);
                let service = config.service;
                let url = service;

                url = GenericService.addToQueryString(url, "filters", filters);
                config.loading = true;
                let sub = this.masterService.callGetService(url).pipe(
                    finalize(() =>
                    {
                        config.loading = false;
                        //this.loading = false;
                    }))
                    .subscribe(res =>
                    {
                        config.table = res;

                        config.tableForExport = GenericService.clone(config.table);
                        //config["height"] = '500px';
                        FusionChartsService.renderChartFromTable(config);
                    },
                        (err) =>
                        {
                            console.log(err);
                        });

                this.gs.ajaxSubscription.push(sub);
            }
        }
    }


    ngOnDestroy()
    {
        this.gs.unsubscribe();
    }

    applyFilters()
    {
        GlobalService.emitFilterChanges();
    }

    //changeTab(tabName)
    //{
    //    this.selectedTab = tabName;
    //    if (tabName == 'Brand')
    //    {
    //        this.gs.filters.filter(x => x.Title == "Brands")[0].Selected = this.gs.filters.filter(x => x.Title == "Brands")[0].List[0].Id;
    //        this.applyFilters();
    //    }
    //    return false;
    //}

    changeTab(tabName)
    {
        this.selectedTab = tabName;
        if (tabName == 'Brand')
        {
            this.gs.filters.filter(x => x.Title == "Brands")[0].Selected = this.gs.filters.filter(x => x.Title == "Brands")[0].List[0].Id;
            this.applyFilters();
            this.run();
        }
        else if (tabName == 'Overall')
        {
            this.gs.filters.filter(x => x.Title == "Brands")[0].Selected = null;
            this.run();

        }
        return false;
    }
}
