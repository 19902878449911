import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from '../../../providers/generic.service';
import { MENU } from '../../../../settings/menu';
import { GlobalService } from 'app/providers/global.service';
import { MasterService } from 'app/providers/master.service';
import { environment } from '../../../../environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
   
    selector: 'vertical-navbar',
    templateUrl: 'vertical-navbar.component.html',
    styleUrls: ['vertical-navbar.component.scss'],
    host: { 'class': 'vertical-navbar' }
})
export class VerticalNavbarComponent
{
    path;
    menuItems;
    subscription;
    gs = GlobalService;
    loading;
    ajaxSubscription: any;

    constructor(private router: Router, private masterService: MasterService)
    {

    }

    ngOnInit()
    {
        this.filterMenuItems();

        this.router.navigate(['/screener']);
    }

    filterMenuItems()
    {
        this.menuItems = GenericService.clone(MENU);
    }

    open(path)
    {
        if (path)
            this.path = null;
        else
            path = this.path;

        this.router.navigate([path]);
    }

    exportToPPT()
    {
        let wavesArr = GlobalService.filters.filter(x => x.Id == "Wave");
        let wavesStr = GenericService.toCsv(wavesArr, null, ",");
        
        if (this.ajaxSubscription)
            this.ajaxSubscription.unsubscribe();
        var url = "ppt/create?wave=" + this.gs.getLastWave() + "&selectedWaves=" + wavesStr;

        this.loading = true;
        this.ajaxSubscription = this.masterService.callGetService(url).pipe(
            finalize(() =>
            {
                this.loading = false;
            }))
            .subscribe(fileName =>
            {
                location.href = environment.baseUrl + "download-ppt?fileName=" + fileName;
            },
                (err) =>
                {
                    this.loading = false;
                    console.log(err);
                });
    }

    filterChange(filter)
    {
        if (filter.Change && GlobalService[filter.Change])
            GlobalService[filter.Change](this.masterService);
    }

    applyFilters()
    {
        GlobalService.emitFilterChanges();
    }

    resetFilters()
    {
        GlobalService.resetFilters();
    }
}
