import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform
{
    transform(items: any[], field: string, value: string): any[]
    {
        if (value == undefined || value == null || value=="")
            return items;
        if (!items) return [];
        return items.filter(it => it[field].toUpperCase().indexOf(value.toUpperCase())>-1);
    }
}