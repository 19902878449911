import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MENU } from '../../../../settings/menu';
import { GenericService } from '../../../providers/generic.service';
import { GlobalService } from '../../../providers/global.service';

@Component({
   
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    host: {
        'class': 'app-menu'
    }
})
export class MenuComponent implements OnInit
{
    menuItems: any;
    subscription: any;
    gs = GlobalService;

    constructor(private router: Router)
    {
        //This component is not being used in this project
        this.menuItems = GenericService.clone(MENU);
    }

    getLiClasses(item: any, isActive: any)
    {
        return {
            'has-sub': item.sub,
            'active': item.active || isActive,
            'menu-item-group': item.groupTitle,
            'disabled': item.disabled
        };
    }
    getStyles(item: any)
    {
        return {
            'background': item.bg,
            'color': item.color
        };
    }

    ngOnInit(): void
    {
    }

    toggle(event: Event, item: any, el: any)
    {
        event.preventDefault();

        let items: any[] = el.menuItems;

        if (item.active)
        {
            item.active = false;
        }
        else
        {
            for (let i = 0; i < items.length; i++)
            {
                items[i].active = false;
            }
            item.active = true;
        }
    }

    open(item)
    {
        if (item.params)
            this.router.navigate([item.routing, item.params]);
        else
            this.router.navigate([item.routing]);
    }

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
