import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth.service';
import { GenericService } from '../../providers/generic.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class PageLoginComponent
{
    // model: any = { userName: 'xyz' };
    model: any = { userName: '' };
    loading = false;
    error = '';
    uaeDate: any;

    constructor(private router: Router, private authService: AuthService)
    {
        setInterval(() => { this.showUAETime(); }, 1000);
    }

    showUAETime()
    {
        var d = new Date();
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        this.uaeDate = new Date(utc + (3600000 * 4));
    }

    login()
    {
        if (this.model.userName == "admin" && this.model.password == "123")
        {
            this.router.navigate(['/screener']);
        }

        //this.loading = true;
        //this.error = null;
        //this.authService.login(this.model.userName, this.model.password)
        //    .subscribe(
        //    (user) =>
        //    {
        //        this.loading = false;
        //        this.router.navigate(['/screener']);
        //    },
        //    (err) =>
        //    {
        //        this.loading = false;
        //        this.error = GenericService.fetchErrorMsg(err);
        //        if (!this.error)
        //            this.error = "Unable to connect to server.";
        //    }
        //    );
    }
}
