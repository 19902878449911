export const MENU: any = [
    {
        title: 'SCREENER',
        icon: {
            class: 'fa fa-laptop-code',
            bg: '#00568A',
            color: '#fff'
        },
        routing: '/screener'
    }
]