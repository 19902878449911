import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'yesNo'
})
@Injectable()
export class YesNoPipe implements PipeTransform
{
    transform(value: any): string
    {
        if (value == true || value == 1 || value == "1" || value == "TRUE" || value == "true")
            return "No";
        else
            return "Yes";
    }
}