import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericService } from '../providers/generic.service';

@Injectable()
export class GlobalService
{
    static path: string;
    static subscription: any;
    static ajaxSubscription: any = [];

    private static _defaultFilters: any[] =
        [
            //    { Id: "Wave", Title: "Wave", Selected: ['201902'], List: [{ 'Id': '201902', Title: "Wave 2'19" },{ 'Id': '201901', Title: "Wave 1'19" }, { 'Id': '201802', Title: "Wave 2'18" }, { 'Id': '201801', Title: "Wave 1'18" }] },
            //{ Id: "City", Title: "City" },
            //{ Id: "S1Rec", Title: "Specialty" },
            { Id: "Q3", Title: "Brands", Clearable: false, Multiple: false, List: [] },
            { Id: "Age", Title: "Gender", Clearable: true, Multiple: false, List: [] },
            { Id: "Q7", Title: "Age", Clearable: true, Multiple: false, List: [] },
            { Id: "Q8", Title: "Region", Clearable: true, Multiple: false, List: [] },

        ];

    static selectedFilters: string;

    static ajaxSubscriptionsMap = {};

    private static _titleObserver = new Subject();
    static titleObservable = GlobalService._titleObserver.asObservable();

    static changeTitle(newTitle: string)
    {
        this._titleObserver.next(newTitle);
        this.routeChange();
    }

    private static _filters: any[];
    static get filters()
    {
        if (!this._filters || this._filters.length == 0)
            this._filters = GenericService.clone(this._defaultFilters);
        return this._filters;
    }

    static set filters(filters)
    {
        this._filters = filters;
        this.emitFilterChanges();
    }

    private static _filtersObserver = new Subject();
    static filtersObservable = GlobalService._filtersObserver.asObservable();
    static emitFilterChanges(createClone = false)
    {
        if (createClone)
            this._filters = GenericService.clone(this.filters);
        this._filtersObserver.next(this.filters);
    }

    static areFiltersLoaded()
    {
        return this.filters && this.filters.length > 0 && this.filters[0].List && this.filters[0].List.length > 0;
    }

    static loadFilters(masterService)
    {
        let filterIds = this._defaultFilters.map(x => x.Id);

        masterService.callService("variable-label/list?surveyCode=psdemo3", true)
            .subscribe(
                (variableLabels) =>
                {
                    this.path = GenericService.getCurrentPath();
                    for (let f of this.filters)
                    {
                        let vl = variableLabels.find(x => x.Name == f.Id);
                        f.List = vl ? Object.keys(vl.ValueLabels).map(key =>
                        {
                            let obj: any = { Id: vl.ValueLabels[key].Value, Title: vl.ValueLabels[key].Label };
                            return obj;
                        }) : [];

                        //if (f.List.length > 0)
                        //    f.List[0].Selected = true;

                        f.Multiple = f.Multiple == undefined ? true : f.Multiple;
                        if (f.Pages && !f.Pages.find(x => x == this.path.replace("/", "")))
                            f.Visible = false;

                        f.Visible = f.Visible == undefined ? true : f.Visible;
                        this.showHideFilters();

                        f.Clearable = f.Clearable == undefined ? true : f.Clearable;
                        f.EnableSearch = false;

                        if (f.Change && GlobalService[f.Change] && f.Selected && f.Selected.length > 0)
                            GlobalService[f.Change](masterService);


                    }
                    this.emitFilterChanges();
                });
    }

    static showHideFilters()
    {
        for (let f of this.filters)
        {
            f.Visible = true;
        }
    }

    static resetFilters()
    {
        this.path = GenericService.getCurrentPath();
        for (let f of this.filters)
        {
            if (f.Visible)
            {
                let df = this._defaultFilters.find(x => x.Id == f.Id);
                f.Selected = df.Selected ? GenericService.clone(df.Selected) : null;
            }
        }
    }

    static getMongoFiltersObj(filters = null, excludedFilters = null, excludeCardio = null)
    {
        this.getSelectedFilters();
        filters = filters || this.filters;
        var obj = { "$and": [] };
        for (let f of filters)
        {
            if (f.Selected && f.Visible != false)
            {
                let temp = {};
                if (excludedFilters && excludedFilters.find(x => x == f.Id))
                    continue;
                if (f.Multiple == false)
                    temp[f.Id] = this.toNumber(f.Selected);
                else
                {
                    if (f.Selected.length > 1)
                        temp[f.Id] = { $in: this.toNumber(f.Selected) };
                    else
                        temp[f.Id] = this.toNumber(f.Selected[0]);
                }
                obj["$and"].push(temp);
            }
        }

        if (obj["$and"].length == 0)
            return null;

        return obj;
    }

    static addToMongoFilters(mongoFilters, filtersToAdd)
    {
        if (!mongoFilters)
            mongoFilters = { "$and": [] };

        mongoFilters["$and"] = mongoFilters["$and"].concat(filtersToAdd);

        return mongoFilters;
    }

    static serializeFilters(filters = null, excludedFilters = null, excludeCardio = null)
    {
        var obj = this.getMongoFiltersObj(filters, excludedFilters, excludeCardio);
        if (!obj)
            return "";

        let str = JSON.stringify(obj);
        return str;
    }

    static toNumber(arrOrValue)
    {
        if (Array.isArray(arrOrValue))
            return arrOrValue.map(x => !isNaN(x) ? parseFloat(x) : x);
        else
            return !isNaN(arrOrValue) ? parseFloat(arrOrValue) : arrOrValue;
    }

    static getSelectedFilters(filters = null)
    {
        filters = filters || this.filters;
        var strFilters = "";
        for (let f of filters)
        {
            if (f.List && f.List.length > 0 && f.Selected && f.Visible != false)
            {
                if (f.Multiple != false)
                {
                    if (f.Selected.length > 0)
                    {
                        let selected = f.List.filter(x => f.Selected.find(y => y == x.Id));
                        strFilters += " | <b>" + f.Title + ":</b> " + GenericService.toCsv(selected, 'Title');
                    }
                }
                else
                {
                    let val = f.List.find(x => f.Selected == x.Id);
                    strFilters += " | <b>" + f.Title + ":</b> " + val.Title;
                }
            }
        }
        if (strFilters.length > 0)
            strFilters = strFilters.substring(5);

        this.selectedFilters = strFilters

        return strFilters;
    }

    static reloadFilter(masterService, filterId, serviceName, filters = null)
    {
        if (this.ajaxSubscriptionsMap[filterId])
            this.ajaxSubscriptionsMap[filterId].unsubscribe();

        filters = filters || this.filters;
        let filter = filters.find(x => x.Id == filterId);
        var url = serviceName;
        var filterStr = this.serializeFilters(null, filters);
        url = GenericService.addToQueryString(url, 'filters', filterStr);

        this.ajaxSubscriptionsMap[filterId] = masterService.callService(url, true)
            .subscribe(
                (list) =>
                {
                    filter.List = list;
                    if (filter.List && filter.List.length > 0 && filter.Selected && filter.Selected.length > 0)
                    {
                        if (filter.Multiple == false)
                        {
                            let selected = list.find(x => x.Code == filter.Selected);
                            if (!selected)
                                filter.Selected = null;
                        }
                        else
                        {
                            filter.Selected = filter.Selected.filter(x => list.find(y => y.Id == x));
                        }
                    }
                });
    }

    static routeChange()
    {
        this.path = GenericService.getCurrentPath();
        this.showHideFilters();
    }

    static unsubscribeAjax()
    {
        for (let i = 0; i < this.ajaxSubscription.length; i++)
        {
            if (this.ajaxSubscription[i])
                this.ajaxSubscription[i].unsubscribe();
        }
        this.ajaxSubscription = [];
    }

    static unsubscribe()
    {
        if (this.subscription)
            this.subscription.unsubscribe();

        this.unsubscribeAjax();
    }

    static getLastWave()
    {
        let value;
        let f = this.filters.find(x => x.Id == 'Wave');
        if (!f.List || f.List.length == 0)
            value = 201901;
        else
        {
            let selected = f.Selected;
            if (!selected || selected.length == 0)
                value = f.List.slice(0, 1).map(x => x.Id)[0];
            else
                value = selected[0];
        }
        return this.toNumber(value);
    }
}

