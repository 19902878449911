import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default/default.component';
import { ExtraLayoutComponent } from './layouts/extra/extra.component';
import { PageLoginComponent } from './pages/login/login.component';
import { ScreenerComponent } from './pages/screener/screener.component';

const defaultRoutes: Routes =
    [
        { path: 'screener', component: ScreenerComponent }
    ];

const extraRoutes: Routes = [
    { path: 'login', component: PageLoginComponent }
];

export const routes: Routes =
    [
        {
            path: '',
            redirectTo: '/screener',
            pathMatch: 'full'
        },
        {
            path: '',
            component: DefaultLayoutComponent,
            children: defaultRoutes
        },
        {
            path: '',
            component: ExtraLayoutComponent,
            children: extraRoutes
        },
        {
            path: '**',
            component: DefaultLayoutComponent,
            children: defaultRoutes
        }
    ];

@NgModule({
    imports: [],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
